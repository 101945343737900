﻿.page-submit {
    padding: 1em;

    @media screen and (min-width: 601px) {
        padding: 0;
        padding-top: 3em;
    }

    .main-content {
        padding: 2em 0;
        background-color: #fff;
        margin: auto;
        border-radius: 4px;

        @media screen and (min-width: 601px) {
            padding: 3em;
            max-width: 80%;
        }
    }

    .header {
        max-width: 95%;
        margin: auto;

        @media screen and (min-width: 601px) {
            max-width: 80%;
        }

        img {
            height: 60px;
            width: 60px;
        }

        h3 {
            font-family: "Fedra Sans Medium";
            font-size: 1.8rem;
            color: $green;
        }
    }

    .content {
        line-height: 26px;
        max-width: 85%;
        margin: auto;
        text-align: left;
        padding-top: 2em;
        white-space: pre-line;

        @media screen and (min-width: 601px) {
            max-width: 80%;
        }

        @media screen and (max-width: 600px) {
            min-height: 80%;
        }
    }

    .extra-content {
        padding: 2em;
        padding-bottom: 0;
        width: 100%;
        margin: auto;


        @media screen and (min-width: 601px) {
            width: 80%
        }

        button, a {
            padding-left: .5em;
            padding-right: .5em;
            margin-bottom: 1em;
            width: 100%;

            @media screen and (min-width: 993px) {
                width: 45%;
            }

            &.gray {
                background-color: $dark-gray;

                &:hover {
                    background-color: lighten($dark-gray, 5);
                }
            }
        }
    }
}
