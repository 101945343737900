﻿// Source colors
$base-background-color: #F7F7F7;
$background-color-darker: #F2F2F2;
$base-font-color: #242424;


// Misc colors
$green: #017B84;
$orange: #F06D00;
$red: #CC0000;
$light-gray: #DDD;
$medium-gray: #B5B5B5;
$dark-gray: #6D6D6D;

$content-width: 70%;
$content-width-small: 80%;