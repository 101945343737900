﻿.page-index {
    background-color: $base-background-color;
}

.page-spinner {
	margin-top: 3em;

	.spinner-layer {
		border-color: $orange;
	}
}

.page-index-header {
    padding: 2em 1em;

    @media screen and (min-width: 601px) {
        padding: 3em 0 2em 0;
    }
}

/* Person info section */
.personInfo {
    width: $content-width-small;
    margin: auto;
    margin-bottom:2em;

    @media screen and (min-width: 601px) {
        width: $content-width;
    }
}

/* Object info section */
.objectInfo {
    width: $content-width-small;
    margin: auto;
    margin-bottom: 2em;

    @media screen and (min-width: 601px) {
        width: $content-width;
    }
}


/* Additional info section */
.additionalInfo {
    width: $content-width-small;
	margin: auto;
	padding-top: 10px;

    @media screen and (min-width: 601px) {
        width: $content-width;
    }

	.add-cosigner,
    .citizenship {
        padding-bottom: 2em;
    }

    .citizenshipYear {
        margin-bottom: 0;
    }

    .citizenshipYear input {
        margin-bottom: 0;
    }

    input[type="radio"] {
        transform: scale(1);
    }
}


.input-box-wrapper {
    position: relative;
    height: 94px;

    @media screen and (max-width: 992px) {
        margin-bottom: 1em;
    }

    label {
        white-space: nowrap;
    }

    img {
        cursor: pointer;
        max-height: 100%;
    }
}

.select-field-wrapper {
	&.col {
		padding-left: 0;
	}
    .span-error {
        position: absolute;
        left: 11px;
        top: 48px;
    }
}

.radio-group-wrapper {
    position: relative;

    > .span-error {
        position: absolute;
        left: 0px;
        top: 72px;
    }
}

.additional-info-number-input-wrapper {
    margin-bottom:2em;
}

.info-box {
    position: absolute;
    bottom: 8em;
    z-index: 10;
    max-width: 240px;
    background-color: $green;
    color: #fff;
    padding: 1.5em;
    border-radius: 2px;

    h4 {
        margin: 0;
        font-family: "Fedra Sans Medium";
        font-size: 1.1rem;
    }

    p {
        font-family: "Fedra Sans Light";
    }
}

.info-box-big {
	position: absolute;
	z-index: 10;
	background-color: $green;
	color:#fff;
	padding:1.5em;
	border-radius: 2px;
	bottom: 1em;
	text-align: left;

	h4 {
        margin: 0;
        font-family: "Fedra Sans Medium";
        font-size: 1.1rem;
    }

	p{

		font-family: "Fedra Sans Light";
	}
}

.info-box-bottom {
	position:fixed;
	bottom: 10em;
}

.input-box {
    border: 1px solid $medium-gray;
    border-radius: 2px;
    background-color: #fff !important;
    padding: .2em;

    &.error {
        border-color: $red;
    }

    .input-box-content {
        border: 0;
        font-size: 1.2rem;
        width: 85%;
        max-width: 85%;
        padding: 0.6em 0.5em;
    }

    & span {
        text-align: right;
        padding-right: 0.5em;
        padding-left: 0.2em;
        opacity: .5;
    }
}

.span-error {
    font-size: .8rem;
    color: $red;
}
.span-error-equity {
    font-size: .9rem;
    color: $red;
}

/* Equity info section */
.equityInfo {
    width: $content-width-small;
    margin: auto;
    margin-bottom: 3em;
    padding-top: 20px;

    @media screen and (min-width: 601px) {
        width: $content-width;
    }
}

/* Loan details section*/
.loanDetails {
    width: $content-width-small;
    margin: auto;

    @media screen and (min-width: 601px) {
        width: $content-width;
    }

    .calculation-details-text:hover {
        cursor: pointer;
    }
}

.calculation-text {
    @media screen and (max-width: 992px) {
        text-align: center;
    }
}

.calculation {
    height: 30px;
    width: 90%;
    font-size: 32px;
    line-height: 30px;
    color: $orange;

    @media screen and (max-width: 992px) {
        width: 100%;
    }

    &.range-field {
        margin-bottom: 1em;
    }

    .calculation-sum {
        margin: auto;

        @media screen and (min-width: 993px) {
            text-align: left;
        }
    }

    img {
        cursor: pointer;
    }

    .calculation-left-wrapper {
        height: 100%;
        cursor: pointer;
    }

    .calculation-right {
        float: right;
    }

    .calculation-middle {
        margin: auto;
    }

    .orangeNumberYear {
        padding-left: 57px;
        padding-right: 56px;
        color: $orange;
    }
}

.loader {
    display: inline-block;
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid $green; /* Blue */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin .9s linear infinite;
}

.rectangle-monthlyFee {
    height: 40px;
    width: 100%;
    background-color: $light-gray;
    margin-bottom: 20px;

    &.bigger {
            height: 3.6em;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .monthlyFee {
        font-family: "Fedra Sans Medium";
        font-weight: normal;
        height: 100%;

        @media screen and (max-width: 600px) {
            background-color: $light-gray;
        }
    }
}

.combined-shape {
    height: 2px;
    width: 12px;
}

.calculation-details-wrapper {
    margin-bottom: 2em;
}


.calculation-details-text {
    text-decoration: underline;

    img {
        margin-right: .5em;
    }
}

.calculation-details {
    text-align: left;

    p {
        margin-top: 2em;
        line-height: 22px;
        margin-bottom: 0;
    }

    td {
        white-space: nowrap;
        text-align: left;
        float: left;
    }

}




/* Submit section */
.page-index-submit-wrapper {
    width: 100%;
    margin: auto;
    background-color: #fff;
    padding: 2em 0 3em 0;
    text-align: left;

    @media screen and (max-width: 600px) {

    }

    .loader {
        position: relative;
        top: 1.2em;
        left: .2em;
    }

    .submit-content {
        width: 80%;
        margin: auto;

        @media screen and (min-width: 601px) {
            width: 70%;
        }
    }

    .submit-button-wrapper {
        @media screen and (max-width: 600px) {
            text-align: center;
		}
		fill: currentColor;

		button {
			display: flex;
			align-items: center;
		}
    }

    .checkbox {
        background-color: $orange;
    }

    button {
        margin-top: 20px;
    }

    .submit-loader-text {
        font-size: .95rem;
        color: $dark-gray;
    }
}

/* Hacky materialize fix to counter 'row' margin without breaking the new-line we want from 'row' */
.select-wrapper {
    margin-top: 1rem;
    margin-left: .75rem;
    margin-right: .75rem;
}

.citizenship > p {
    margin-left: .75rem;
    margin-right: .75rem;
}

/* ****** Materialize overrides ***** */

/* Radio button - inner circle selected*/
[type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:after {
    background-color: $orange;
}
/* Radio button - outer circle selected */
[type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:before, [type="radio"].with-gap:checked + label:after {
    border-color: #000;
    border: 1px solid #000;
}
/* Radio button - outer circle unselected */
[type="radio"]:not(:checked)+label:before, [type="radio"]:not(:checked)+label:after {
    border: 1px solid #000;
}
/* Radio button - outer circle */
[type="radio"] + label:before, [type="radio"] + label:after {
    transform: scale(1.5);
}
/* Radio button - inner circle */
[type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:after {
    transform: scale(.9);
    border: 0;
}

$checkbox-offset: 4px;

[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
    border-color: $orange;
    background-color: $orange;
}
/* Checked box */
[type="checkbox"].filled-in:checked + label:after {
    top: $checkbox-offset;
    border-color: $orange;
    background-color: $orange;
}
/* Checked marker */
[type="checkbox"].filled-in:checked + label:before {
    top: $checkbox-offset;
}
/* Not checked */
[type="checkbox"].filled-in:not(:checked) + label:after {
    top: $checkbox-offset;
}

.input-field {
	margin-bottom: 2rem;
}

.input-field label {
    font-size: .8rem;
    text-transform: uppercase;
    color: $base-font-color;
}

.highlight {
    margin-top: 3rem;
    padding-bottom: 1rem;
}

.highlight label {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
}
