﻿/* Typography */
@font-face {
    font-family: "Fedra Sans";
    src: url(../fonts/FedraSansAltPro-Book.otf) format('opentype');
}

@font-face {
    font-family: "Fedra Sans Light";
    src: url(../fonts/FedraSansAltPro-Light.otf) format('opentype');
}

@font-face {
    font-family: "Fedra Sans Medium";
    src: url(../fonts/FedraSansAltPro-Medium.otf) format('opentype');
}

@font-face {
    font-family: "Fedra Sans Bold";
    src: url(../fonts/FedraSansAltPro-Bold.otf) format('opentype');
}


/* General */
body {
    font-family: "Fedra Sans";
    background-color: rgb(238, 238, 238);
}

.container {
    @media screen and (min-width: 601px) {
        margin-top: 20px;
        border-radius: 15px;
    }

    /* Override default container width on mobile*/
    @media screen and (max-width: 600px) {
        width: 100%;
    }

    @media screen and (min-width: 993px) {
        width: 80%;
    }
}

label {
    font-size: .9rem;
    color: $base-font-color;
}

p {
    text-align: left;
}


/* Headers */
h3 {
    margin-bottom: 0;
}

.section-header-wrapper {
    margin: auto;
    padding-top: 2em;
    width: $content-width-small;
    text-align: left;

    @media screen and (min-width: 601px) {
        width: $content-width;
    }

    h5 {
        margin-left: .5em;
    }
}

/* Main content wrapper */
.page-submit-wrapper {
    background-color: $background-color-darker;
}


/* Extra space between sections, skip first section */
.section-header-wrapper ~ .section-header-wrapper {
    margin-top: 2em;
}


/* HR and divider */
hr {
    border-color: $light-gray;
}

.divider {
    width: $content-width-small;
    margin: auto;

    @media screen and (min-width: 601px) {
        width: $content-width;
    }
}

/* Logo */
.logo {
    width: 100%;
    height: 55px;
    margin: auto;
    background-color: $green;
    text-align: center;

    @media screen and (min-width: 601px) {
        border-radius: 10px 10px 0 0;
        height: 100px;
    }

    & img {
        max-height: 100%;
    }
}

/* Default tables */
tr {
}

th, td {
    padding-bottom: 0;
    padding-top: 10px;
}

th {
    font-family: "Fedra Sans Bold";
}

td {
    text-align: right;
}


/* Remove arrows from number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type=range] {
    border: none;
    height: 30px;

    &::-webkit-slider-thumb {
        background-color: $green;
        height: 20px;
        width: 20px;
        margin-top: -8px;
    }

    &::-moz-range-thumb {
        background-color: $green;
        height: 20px;
        width: 20px;
    }

    &::-ms-thumb {
        background-color: $green;
        height: 20px;
        width: 20px;
        margin-top: 0; // Edge fix
    }

    &::-webkit-slider-runnable-track {
        background: $medium-gray;
    }

    &::-moz-range-track {
        background: $medium-gray;
    }

    &::-ms-fill-lower {
        background: $medium-gray;
    }

    &::-ms-fill-upper {
        background: $medium-gray;
    }

    + .thumb {
        display: none;
    }
}

input[type=text] {
	margin: 0;
}

/* ***** Materialize overwrites ***** */
.dropdown-content li >a,
.dropdown-content li>span {
    color: $base-font-color;
}

.btn {
    background-color: $green;

    &:hover {
        background-color: lighten($green, 5)
    }
}

.btn-large {
    height: 45px;
    line-height:45px;
}

.no-break {
    white-space: nowrap;
}
